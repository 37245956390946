// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cennik-tsx": () => import("./../../../src/pages/cennik.tsx" /* webpackChunkName: "component---src-pages-cennik-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-mm-expanded-tsx": () => import("./../../../src/pages/mm-expanded.tsx" /* webpackChunkName: "component---src-pages-mm-expanded-tsx" */),
  "component---src-pages-mm-tsx": () => import("./../../../src/pages/mm.tsx" /* webpackChunkName: "component---src-pages-mm-tsx" */),
  "component---src-pages-oferta-endodoncja-wagrowiec-tsx": () => import("./../../../src/pages/oferta/endodoncja-wagrowiec.tsx" /* webpackChunkName: "component---src-pages-oferta-endodoncja-wagrowiec-tsx" */),
  "component---src-pages-oferta-implantologia-wagrowiec-tsx": () => import("./../../../src/pages/oferta/implantologia-wagrowiec.tsx" /* webpackChunkName: "component---src-pages-oferta-implantologia-wagrowiec-tsx" */),
  "component---src-pages-oferta-ortodoncja-wagrowiec-tsx": () => import("./../../../src/pages/oferta/ortodoncja-wagrowiec.tsx" /* webpackChunkName: "component---src-pages-oferta-ortodoncja-wagrowiec-tsx" */),
  "component---src-pages-oferta-periodontologia-tsx": () => import("./../../../src/pages/oferta/periodontologia.tsx" /* webpackChunkName: "component---src-pages-oferta-periodontologia-tsx" */),
  "component---src-pages-oferta-protetyka-wagrowiec-tsx": () => import("./../../../src/pages/oferta/protetyka-wagrowiec.tsx" /* webpackChunkName: "component---src-pages-oferta-protetyka-wagrowiec-tsx" */),
  "component---src-pages-oferta-sedacja-wziewna-tsx": () => import("./../../../src/pages/oferta/sedacja-wziewna.tsx" /* webpackChunkName: "component---src-pages-oferta-sedacja-wziewna-tsx" */),
  "component---src-pages-oferta-stomatologia-chirurgiczna-tsx": () => import("./../../../src/pages/oferta/stomatologia-chirurgiczna.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-chirurgiczna-tsx" */),
  "component---src-pages-oferta-stomatologia-dziecieca-tsx": () => import("./../../../src/pages/oferta/stomatologia-dziecieca.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-dziecieca-tsx" */),
  "component---src-pages-oferta-stomatologia-estetyczna-tsx": () => import("./../../../src/pages/oferta/stomatologia-estetyczna.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-estetyczna-tsx" */),
  "component---src-pages-oferta-stomatologia-zachowawcza-tsx": () => import("./../../../src/pages/oferta/stomatologia-zachowawcza.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-zachowawcza-tsx" */),
  "component---src-pages-oferta-tsx": () => import("./../../../src/pages/oferta.tsx" /* webpackChunkName: "component---src-pages-oferta-tsx" */),
  "component---src-pages-regulamin-mediroot-tsx": () => import("./../../../src/pages/regulamin-mediroot.tsx" /* webpackChunkName: "component---src-pages-regulamin-mediroot-tsx" */),
  "component---src-pages-rodo-tsx": () => import("./../../../src/pages/rodo.tsx" /* webpackChunkName: "component---src-pages-rodo-tsx" */),
  "component---src-pages-zespol-tsx": () => import("./../../../src/pages/zespol.tsx" /* webpackChunkName: "component---src-pages-zespol-tsx" */)
}

